.canvasContain {
    position: relative;
    width: 329px;
    height: 329px;
    margin: auto;
}

#myCanvas {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

.canvasContain img {
    position: absolute;
    width: 329px;
    height: 329px;
    margin: auto;
    left: 0;
    right: 0;

}

.label {
    position: absolute;
    border: 1px solid #D8D8D8;
    border-radius: 27.5px;
    width: max-content !important;
    font-size: 11px;
     padding: 4px 8px;
}

.label0 {
    top: 88px;
    left: 320px;
}
.label1 {
    top: 226px;
    left: 320px;
}
.label2 {
    top: 315px;
    left: 205px;
}
.label3 {
    top: 315px;
    left: 68px;
}
.label4 {
    top: 226px;
    left: -65px;
} 
.label5 {
    top: 81px;
    left: -40px;

}
.label6 {
    top: -6px;
    left: 54px;
}
.label7 {
    top: -6px;
    left: 215px;
}

@media (max-width: 480px) {
    .canvasContain, .canvasContain img, #myCanvas {
        width: 180px;
        height: 180px;
    }

    .label {
        font-size: 10px;
    }

    .label0 {
        top: 40px;
        left: 175px;
    }
    .label1 {
        top: 120px;
        left: 175px;
    }
    .label2 {
        top: 175px;
        left: 110px;
    }
    .label3 {
        top: 175px;
        left: 30px;
    }
    .label4 {
        top: 126px;
        left: -60px;
    }
    .label5 {
        top: 44px;
        left: -42px;
    }
    .label6 {
        top: -16px;
        left: -10px;
    }
    .label7 {
        top: -16px;
        left: 115px;
    }
}

.analysis-dialog .MuiDialog-paper{
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
}

.analysis-dialog .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
    width: fit-content;
    padding: 0;
    position: absolute;
    right: 18px;
    top: 18px;
    font-size: 14px;
}

.analysis-dialog-content {
    height: 100%;
}

.scores-con {
    height: 400px;
    overflow: auto;
}

.scores {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.score {
    width: 20%;
    height: 220px;
    padding: 10px 5px;
    margin: 8px 4px;
}

input[type=range][orient=vertical] {
    appearance: slider-vertical;
    width: 8px;
    min-height: 125px;
    padding: 0 5px;
    margin: auto;
}

.score-bars {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 8px;
    height: 200px;
}

.score-label {
    text-align: center;
    display: block;
    font-size: 8px;
    font-weight: bold;
    overflow: hidden;
    padding: 0 5px 5px;
    text-transform: uppercase;
    white-space: nowrap;
}

.score-text {
    min-height: 24px;
}

.bars {
    height: 200px;
    min-width: 8px;
    display: flex;
    flex-direction: column;
}

.bar {
    height: 33%;
    background: #C79182;
    border: 1px #333 solid;
}
.bar:nth-child(2) {
    background: #ECD2CB;
}
.bar:nth-child(3) {
    background: #faeae7;
}

.indicator {
    position: relative;
    top: 0;
}
